<template>
  <div
    class="innerbanner"
    :style="
      banner
        ? `background-image: url(${banner})`
        : `background-image: url(/assets/img/terminal02.jpg)`
    "
  ></div>
</template>
<script>
import TemplateProductBanner from './TemplateProductBanner';

export default {
  mixins: [TemplateProductBanner],
  props: {
    banner: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped></style>
